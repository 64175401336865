import { Entity } from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core/styles';

export const DRUPAL_INSIGHTS_ANNOTATION = 'axelerant.com/drupal-project';

export const isDrupalInsightsAvailable = (entity: Entity) =>
  Boolean(entity?.metadata.annotations?.[DRUPAL_INSIGHTS_ANNOTATION]);

export const alertStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    '& + .MuiAlert-root': {
      marginTop: theme.spacing(3),
    },
  },
}));
