/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Cancel';
import AbortIcon from '@material-ui/icons/ReportOutlined';
import WaitingIcon from '@material-ui/icons/Brightness1Rounded';
import WipIcon from '@material-ui/icons/AdjustTwoTone';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  secondary: {
    fontSize: '0.8rem',
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  waitingIcon: {
    color: theme.palette.warning.main,
  },
}));

export const WorkflowRunStatus = (props: {
  status?: string;
  conclusion?: string;
}) => {
  return (
    <List>
      <ListItem style={{ padding: 0 }}>
        <ListItemIcon style={{ minWidth: '10px' }}>
          <WorkflowIcon {...props} />
        </ListItemIcon>
        <ListItemText primary={getStatusDescription(props)} />
      </ListItem>
    </List>
  );
};

export function WorkflowIcon({
  status,
  conclusion,
}: {
  status?: string;
  conclusion?: string;
}) {
  const classes = useStyles();
  if (status === undefined) return null;
  switch (status.toLocaleLowerCase('en-US')) {
    case 'queued':
      return <WaitingIcon />;

    case 'in_progress':
      return <WipIcon className={classes.waitingIcon} />;
    case 'completed':
      switch (conclusion?.toLocaleLowerCase('en-US')) {
        case 'skipped':
        case 'cancelled':
          return <AbortIcon />;
        case 'timed_out':
          return <WarningIcon className={classes.warningIcon} />;
        case 'failure':
          return <ErrorIcon className={classes.errorIcon} />;
        default:
          return <CheckCircleOutlineIcon className={classes.successIcon} />;
      }
    default:
      return <WaitingIcon />;
  }
}

export function getStatusDescription({
  status,
  conclusion,
}: {
  status?: string;
  conclusion?: string;
}) {
  if (status === undefined) return '';
  switch (status.toLocaleLowerCase('en-US')) {
    case 'queued':
      return 'Queued';
    case 'in_progress':
      return 'In progress';
    case 'completed':
      switch (conclusion?.toLocaleLowerCase('en-US')) {
        case 'skipped':
        case 'cancelled':
          return 'Aborted';
        case 'timed_out':
          return 'Timed out';
        case 'failure':
          return 'Error';
        default:
          return 'Completed';
      }
    default:
      return 'Pending';
  }
}
