import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {
  ComponentParts,
  createCardExtension,
} from '@backstage/plugin-home-react';

import { rootRouteRef } from './routes';

export const homePageEntityCardsPlugin = createPlugin({
  id: 'home-page-entity-cards',
  routes: {
    root: rootRouteRef,
  },
});

export const HomePageEntityCardsPage = homePageEntityCardsPlugin.provide(
  createRoutableExtension({
    name: 'HomePageEntityCardsPage',
    component: () =>
      import('./components/DefaultPage').then(m => m.DefaultPage),
    mountPoint: rootRouteRef,
  }),
);

export const HomePageMyComponentsCard = homePageEntityCardsPlugin.provide(
  createCardExtension<Partial<ComponentParts>>({
    name: 'HomePageMyComponentsCard',
    title: 'My Components',
    components: () => import('./components/MyComponentsCard'),
  }),
);
