import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const onepasswordVaultManagerPlugin = createPlugin({
  id: 'onepassword-vault-manager',
  routes: {
    root: rootRouteRef,
  },
});

export const OnepasswordVaultManagerPage =
  onepasswordVaultManagerPlugin.provide(
    createRoutableExtension({
      name: 'OnepasswordVaultManagerPage',
      component: () => import('./components/VaultPage').then(m => m.VaultPage),
      mountPoint: rootRouteRef,
    }),
  );
