import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { rootRouteRef } from './routes';
import { TechDocPrintButton } from './TechDocPrintButton';

export const techdocAddonsPlugin = createPlugin({
  id: 'techdoc-addons',
  routes: {
    root: rootRouteRef,
  },
});

export const TechdocAddonsPage = techdocAddonsPlugin.provide(
  createRoutableExtension({
    name: 'TechdocAddonsPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);

export const TechDocPrintButtonAddon = techdocAddonsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'TechDocPrintButtonAddon',
    location: TechDocsAddonLocations.Subheader,
    component: TechDocPrintButton,
  }),
);
