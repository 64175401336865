import {
  findCurrentEntityRef,
  CER_HEADER_NAME,
} from '@internal/plugin-proxy-override';

const originalFetch = window.fetch;

// A ditry fix to add current entity ref for plugins which are not using fetchapi wrapper
export function modifyFetch() {
  // Create a wrapper function for fetch that adds headers
  window.fetch = (input, init = {}) => {
    // If hot reload
    if (typeof input === 'string' && input.endsWith('hot-update.json')) {
      return originalFetch(input, init);
    }

    // if first argument is already Request
    if (input instanceof Request) {
      const urlHost = new URL(input.url).hostname;
      if (
        urlHost === window.location.hostname &&
        !input.headers.get(CER_HEADER_NAME)
      ) {
        input.headers.append(CER_HEADER_NAME, findCurrentEntityRef());
      }
      return originalFetch(input, init);
    }

    // If the first argument is a URL
    const urlHost = new URL(input).hostname ?? '';
    if (urlHost === window.location.hostname) {
      const headers = new Headers(init.headers);
      if (!headers.get(CER_HEADER_NAME)) {
        headers.append(CER_HEADER_NAME, findCurrentEntityRef());
      }
      // Call the original fetch with the modified options
      return originalFetch(input, { ...init, headers });
    }

    // Call the original fetch
    return originalFetch(input, init);
  };
}
