import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';

export const TechDocPrintButton = () => {
  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  return (
    <>
      <Button onClick={handlePrint} variant="contained" size="small">
        Print
      </Button>
    </>
  );
};
