import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useRouteRef } from '@backstage/core-plugin-api';
import { buildRouteRef } from '../../routes';
import { WorkflowRun } from '../useWorkflowRuns';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@backstage/core-components';
import { WorkflowIcon } from '../WorkflowRunStatus/WorkflowRunStatus';

const useStyles = makeStyles(_ => ({
  secondary: {
    fontSize: '0.8rem',
  },
}));

const CommitInfo = ({ row }: { row: Partial<WorkflowRun> }) => {
  if (row.event === 'push') {
    return (
      <>
        <span>
          : Commit <u>{row.source?.commit.hash?.substring(0, 7)}</u> pushed by{' '}
          {row.runBy}
        </span>
      </>
    );
  }
  if (row.event === 'pull_request') {
    return (
      <>
        : Pull request{' '}
        {row.pullRequest?.number && <u>#{row.pullRequest.number}</u>} opened by{' '}
        {row.runBy}
      </>
    );
  }
  if (row.event === 'schedule') {
    return <>: Scheduled</>;
  }

  return <></>;
};

const SecondaryMessage = ({ row }: { row: Partial<WorkflowRun> }) => {
  const classes = useStyles();
  return (
    <span className={classes.secondary}>
      <b>{row.workflowName}</b> #{row.runNumber} <CommitInfo row={row} />
    </span>
  );
};

export const WorkflowRunMessageInfo = ({
  row,
}: {
  row: Partial<WorkflowRun>;
}) => {
  const routeLink = useRouteRef(buildRouteRef);
  return (
    <List>
      <ListItem key={row.id!}>
        <ListItemIcon>
          <WorkflowIcon status={row.status} conclusion={row.conclusion} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Link component={RouterLink} to={routeLink({ id: row.id! })}>
              {row.message}
            </Link>
          }
          secondary={<SecondaryMessage row={row} />}
        />
      </ListItem>
    </List>
  );
};

export default WorkflowRunMessageInfo;
