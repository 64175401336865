import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WatchIcon from '@material-ui/icons/AccessAlarm';
import Typography from '@material-ui/core/Typography';
import { WorkflowRun } from '../useWorkflowRuns';
import {
  differenceInMinutes,
  differenceInSeconds,
  formatDistance,
} from 'date-fns';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const formatDuration = (statedAt: string, endAt: string, status: string) => {
  if (status === '') {
    return 'Queued';
  }
  if (status.toLocaleLowerCase('en-US') === 'queued') {
    return 'Queued';
  }
  if (status.toLocaleLowerCase('en-US') === 'in_progress') {
    return 'In Progress';
  }

  const startDate = new Date(statedAt);
  const endDate = new Date(endAt);

  const minutesDifference = differenceInMinutes(endDate, startDate);
  const secondsDifference = differenceInSeconds(endDate, startDate) % 60;

  if (minutesDifference === 0) {
    return `${secondsDifference}s`;
  }

  return `${minutesDifference}m ${secondsDifference}s`;
};

export const WorkflowRunTimeInfo = ({ row }: { row: Partial<WorkflowRun> }) => {
  const classes = useStyles();
  const distance = formatDistance(
    new Date(row.runTime?.started_at ?? new Date()),
    new Date(new Date()),
    { addSuffix: true },
  );
  return (
    <div>
      <div className={classes.container}>
        <CalendarTodayIcon className={classes.icon} />
        <Typography variant="body2">{distance}</Typography>
      </div>

      <div className={classes.container}>
        <WatchIcon className={classes.icon} />
        <Typography variant="body2">
          {formatDuration(
            row.runTime?.started_at ?? '',
            row.runTime?.updated_at ?? '',
            row.status ?? '',
          )}
        </Typography>
      </div>
    </div>
  );
};
