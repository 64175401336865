/*
 * Copyright 2023 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { stringifyEntityRef } from '@backstage/catalog-model';

export const CER_HEADER_NAME = 'X-Current-Entity-Ref';

export const findCurrentEntityRef = (catalogPath: string = 'catalog') => {
  const regex = new RegExp(
    `^\/${catalogPath}\/(?<namespace>[^\/]+)\/(?<kind>[^\/]+)\/(?<name>[^\/]+)`,
  );
  const result = regex.exec(window.location.pathname);
  if (!result || !result?.groups) return '';
  const entity = {
    namespace: result.groups.namespace,
    kind: result.groups.kind,
    name: result.groups.name,
  };
  return stringifyEntityRef(entity);
};

export class InjectEntityRef {
  constructor(public readonly catalogPath: string = 'catalog') {}

  apply(next: typeof fetch): typeof fetch {
    return async (input, init) => {
      const request = new Request(input as any, init);
      if (request.headers.get(CER_HEADER_NAME)) {
        return next(input as any, init);
      }
      const currRef = this.getCurrentEntityRef();
      request.headers.set(CER_HEADER_NAME, currRef);
      return next(request);
    };
  }

  private getCurrentEntityRef() {
    return findCurrentEntityRef(this.catalogPath);
  }
}
