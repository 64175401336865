import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const axelerantDrupalInsightPlugin = createPlugin({
  id: 'axelerant-drupal-insight',
  routes: {
    root: rootRouteRef,
  },
});

export const AxelerantDrupalInsightPage = axelerantDrupalInsightPlugin.provide(
  createRoutableExtension({
    name: 'AxelerantDrupalInsightPage',
    component: () =>
      import('./components/DrupalInsightPage').then(m => m.DrupalInsightPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityDrupalInsightContents = axelerantDrupalInsightPlugin.provide(
  createComponentExtension({
    name: 'EntityDrupalInsightContents',
    component: {
      lazy: () =>
        import('./components/DrupalInsightContents').then(
          m => m.DrupalInsightContents,
        ),
    },
  }),
);

export const EntityDrupalInsightVersionCard =
  axelerantDrupalInsightPlugin.provide(
    createComponentExtension({
      name: 'EntityDrupalInsightVersionCard',
      component: {
        lazy: () =>
          import('./components/Cards/VersionCard').then(m => m.VersionCard),
      },
    }),
  );

export const EntityDrupalInsightModulesCard =
  axelerantDrupalInsightPlugin.provide(
    createComponentExtension({
      name: 'EntityDrupalInsightModulesCard',
      component: {
        lazy: () =>
          import('./components/Cards/ModulesCard').then(m => m.ModulesCard),
      },
    }),
  );

export const EntityDrupalInsightTaxonomyCard =
  axelerantDrupalInsightPlugin.provide(
    createComponentExtension({
      name: 'EntityDrupalInsightTaxonomyCard',
      component: {
        lazy: () =>
          import('./components/Cards/TaxonomyCard').then(m => m.TaxonomyCard),
      },
    }),
  );

export const EntityDrupalInsightUsersCard =
  axelerantDrupalInsightPlugin.provide(
    createComponentExtension({
      name: 'EntityDrupalInsightUsersCard',
      component: {
        lazy: () =>
          import('./components/Cards/UsersCard').then(m => m.UsersCard),
      },
    }),
  );

export const EntityDrupalInsightContentTypeCard =
  axelerantDrupalInsightPlugin.provide(
    createComponentExtension({
      name: 'EntityDrupalInsightContentTypeCard',
      component: {
        lazy: () =>
          import('./components/Cards/ContentTypesCard').then(
            m => m.ContentTypesCard,
          ),
      },
    }),
  );
